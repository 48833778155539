@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 400;
  src: local(''), url("../fonts/gilroy-regular.woff2") format('woff2'), url("../fonts/gilroy-regular.woff") format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  src: local(''), url("../fonts/gilroy-medium.woff2") format('woff2'), url("../fonts/gilroy-medium.woff") format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  src: local(''), url("../fonts/gilroy-semibold.woff2") format('woff2'), url("../fonts/gilroy-semibold.woff") format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  src: local(''), url("../fonts/gilroy-bold.woff2") format('woff2'), url("../fonts/gilroy-bold.woff") format('woff');
  font-display: swap;
}
* {
  margin: 0;
  padding: 0;
  font: inherit;
  box-sizing: border-box;
  will-change: all;
}
*:before,
*:after {
  box-sizing: border-box;
  will-change: all;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-focus-ring-color: rgba(255,255,255,0);
}
body {
  font-size: 100%;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
a {
  background: transparent;
}
*:focus,
*:active,
*:hover {
  outline: 0;
}
hr {
  box-sizing: content-box;
  height: 0;
}
ol,
ul {
  list-style: none;
}
pre {
  tab-size: 4;
  white-space: pre-wrap;
}
q {
  quotes: '\201C' '\201D' '\2018' '\2019';
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
button,
input {
  line-height: normal;
}
button,
select {
  text-transform: none;
}
button {
  overflow: visible;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"],
input[type="email"],
input[type="password"],
input[type="text"],
input[type="phone"] {
  -webkit-appearance: none;
  box-sizing: border-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
button,
input,
select[multiple],
textarea {
  background-image: none;
}
input,
select,
textarea {
  border-radius: 0;
  box-shadow: none;
}
input,
textarea {
  resize: none;
  user-select: text;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html {
  height: 100%;
}
.hide {
  display: none;
}
.hidden {
  overflow: hidden;
}
.clearfix {
  zoom: 1;
}
.clearfix:after {
  clear: both;
  content: '';
  display: block;
  height: 0;
  visibility: hidden;
}
.nobr {
  white-space: nowrap;
}
.italic {
  font-style: italic;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.alignLeft {
  text-align: left;
}
.alignRight {
  text-align: right;
}
.alignCenter {
  text-align: center;
}
a {
  text-decoration: none;
  color: inherit;
}
p {
  margin: 0;
}
img {
  display: block;
  max-width: 100%;
}
strong {
  font-weight: 600;
}
.popupLayer {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: rgba(0,0,0,0.65);
}
.popup {
  display: none;
  position: fixed;
  z-index: 100;
  width: 100%;
  max-width: 68rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 5rem 2rem 5rem;
  box-shadow: 0 0.3rem 0.8rem 0 rgba(0,0,0,0.35);
}
@media (min-width: 480px) {
  .popup {
    padding: 5rem 5rem 5rem;
  }
}
@media (min-width: 920px) {
  .popup {
    width: auto;
  }
}
.popup__wrap {
  display: flex;
  flex-direction: column;
  gap: min(calc(0.8rem + 0.42vw), 1.6rem);
}
.popup__closer {
  position: absolute;
  z-index: 2;
  top: 1rem;
  right: 2rem;
  width: 5rem;
  height: 5rem;
}
.popup__closer:before,
.popup__closer:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 0.2rem;
  background: #24272b;
  border-radius: 0.2rem;
  transition: 0.3s background;
}
@media (hover: hover) and (min-width: 992px) {
  .popup__closer:hover:before,
  .popup__closer:hover:after {
    background: #fe3501;
  }
}
.popup__closer:before {
  transform: rotate(-45deg);
}
.popup__closer:after {
  transform: rotate(45deg);
}
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
body {
  color: #262626;
  font-size: min(calc(1.4rem + 0.21vw), 1.8rem);
  font-weight: 400;
  min-width: 32rem;
  height: 100%;
  line-height: 1;
  font-family: Gilroy, Arial, sans-serif;
}
.cursorWait {
  cursor: wait;
}
.noscript {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 2rem;
  line-height: 1.2;
  background-color: #fff;
  z-index: 999;
}
.lnk {
  background: #1b6ecf;
  color: #fff;
  font-weight: 700;
  padding: 1.5rem;
  fill: #fff;
  display: inline-flex;
  align-items: center;
  gap: 0 3.4rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  position: relative;
  font-size: min(calc(1.4rem + 0.31vw), 2rem);
  border: 0.1rem solid #1b6ecf;
  transition-duration: 0.32s;
}
.lnk:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 0.1rem;
  background: #fff;
  right: 5rem;
  transition-duration: 0.32s;
}
@media (hover: hover) and (min-width: 992px) {
  .lnk:hover {
    background: #fff;
    color: #1b6ecf;
    fill: #1b6ecf;
  }
}
@media (hover: hover) and (min-width: 992px) {
  .lnk:hover:before {
    background: #1b6ecf;
  }
}
.lnk__ico {
  width: 1.7rem;
  height: 1.7rem;
}
.box {
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 2rem;
  max-width: 180rem;
}
@media (min-width: 992px) {
  .box {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}
.title {
  font-size: min(calc(1.4rem + 1.46vw), 4.2rem);
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}
@media (min-width: 992px) {
  .title {
    font-size: min(calc(1.4rem + 0.73vw), 2.8rem);
  }
}
.beforeHide {
  opacity: 0;
  transition-duration: 0.55s;
  transform: translate(0, -3.5rem);
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 8;
  color: #262626;
  box-shadow: 0 0 0.8rem rgba(0,0,0,0.75);
  font-size: min(calc(1.4rem + 0.42vw), 2.2rem);
  font-weight: 600;
  transition-duration: 0.32s;
  background: #fff;
  fill: #262626;
}
.header--transparent {
  color: #fff;
  fill: #fff;
  text-shadow: 0 0 1rem rgba(0,0,0,0.35);
  box-shadow: none;
  background: transparent;
}
.header--transparent .openNav:before,
.header--transparent .openNav:after,
.header--transparent .openNav__inner {
  background: #fff;
}
.header--transparent .logo__img--dark {
  display: none;
}
.header--transparent .logo__img--white {
  display: block;
}
.header__box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 1.8rem;
  padding-bottom: 1.8rem;
  z-index: 2;
  gap: 1rem 0;
}
@media (min-width: 840px) {
  .header__box {
    flex-wrap: nowrap;
  }
}
@media (min-width: 1120px) {
  .header--transparent .header__box {
    padding-top: 4.1rem;
  }
}
.header__divide {
  width: 100%;
}
@media (min-width: 840px) {
  .header__divide {
    display: none;
  }
}
.header__logo {
  max-width: 18rem;
  flex-shrink: 0;
}
@media (min-width: 1120px) {
  .header__logo {
    max-width: 30.2rem;
  }
}
.header__phone {
  order: 1;
}
@media (min-width: 840px) {
  .header__phone {
    order: inherit;
    padding-left: min(calc(4.1rem + 2.14vw), 8.2rem);
  }
}
.header__mail {
  order: 1;
  padding-left: min(calc(2.1rem + 1.09vw), 4.2rem);
}
@media (min-width: 840px) {
  .header__mail {
    order: inherit;
    display: flex;
    justify-content: flex-start;
    padding-left: min(calc(3.3rem + 1.72vw), 6.6rem);
  }
}
.header__linkNav {
  margin-left: auto;
}
.openNav {
  width: 5rem;
  height: 3rem;
  position: relative;
  cursor: pointer;
}
@media (min-width: 1120px) {
  .openNav {
    width: 6.5rem;
    height: 4.4rem;
  }
}
.openNav:before,
.openNav:after,
.openNav__inner {
  position: absolute;
  background: #262626;
  width: 100%;
  height: 0.4rem;
  border-radius: 0.3rem;
  left: 0;
}
@media (min-width: 1120px) {
  .openNav:before,
  .openNav:after,
  .openNav__inner {
    height: 0.6rem;
  }
}
.openNav:before,
.openNav:after {
  content: '';
  z-index: 1;
  transition: top 0.32s 0.32s, bottom 0.32s 0.32s, margin 0.32s 0.32s, width 0s 0.32s, transform 0.32s 0s;
}
.openNav:before {
  top: 0;
}
.openNav:after {
  bottom: 0;
}
.openNav__inner {
  top: 50%;
  transform: translate(0, -50%);
}
@media (hover: hover) and (min-width: 992px) {
  .openNav:hover:before,
  .openNav:hover:after {
    width: 50%;
    transition: top 0.32s 0s, bottom 0.32s 0s, margin 0.32s 0s, width 0s 0.32s, transform 0.32s 0.32s;
  }
}
@media (hover: hover) and (min-width: 992px) {
  .openNav:hover:before {
    top: 50%;
    margin: -0.2rem 0 0 0;
    transform: translate(-0.4rem, -0.8rem) rotate(-45deg);
  }
}
@media (min-width: 1120px) {
  .openNav:hover:before {
    margin: -0.3rem 0 0 0;
    transform: translate(-0.5rem, -1rem) rotate(-45deg);
  }
}
@media (hover: hover) and (min-width: 992px) {
  .openNav:hover:after {
    bottom: 50%;
    margin: 0 0 -0.2rem 0;
    transform: translate(-0.4rem, 0.8rem) rotate(45deg);
  }
}
@media (min-width: 1120px) {
  .openNav:hover:after {
    margin: 0 0 -0.3rem 0;
    transform: translate(-0.5rem, 1rem) rotate(45deg);
  }
}
.closeNav {
  width: 5rem;
  height: 5rem;
  position: relative;
  cursor: pointer;
}
@media (min-width: 1120px) {
  .closeNav {
    width: 6.5rem;
    height: 6.5rem;
  }
}
.closeNav:before,
.closeNav:after {
  content: '';
  transition: transform 0.6s 0s, transform-origin 0s 175ms, width 0s 175ms;
}
.closeNav:before,
.closeNav:after,
.closeNav__inner {
  position: absolute;
  background: #fff;
  width: 100%;
  height: 0.4rem;
  border-radius: 0.2rem;
  right: 0;
  top: 50%;
}
@media (min-width: 1120px) {
  .closeNav:before,
  .closeNav:after,
  .closeNav__inner {
    height: 0.6rem;
    border-radius: 0.3rem;
  }
}
.closeNav__inner {
  opacity: 0;
  transition: opacity 0s 175ms;
}
.closeNav:before {
  transform: rotate(-45deg);
}
.closeNav:after {
  transform: rotate(45deg);
}
@media (hover: hover) and (min-width: 992px) {
  .closeNav:hover .closeNav__inner {
    opacity: 1;
  }
}
@media (hover: hover) and (min-width: 992px) {
  .closeNav:hover:before,
  .closeNav:hover:after {
    width: 50%;
    transform-origin: 100% center;
    transition: transform 0.6s 0s, transform-origin 0s 175ms, width 0s 175ms;
  }
}
@media (hover: hover) and (min-width: 992px) {
  .closeNav:hover:before {
    transform: rotate(45deg);
  }
}
@media (hover: hover) and (min-width: 992px) {
  .closeNav:hover:after {
    transform: rotate(-45deg);
  }
}
.phone,
.mail {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.phone__text,
.mail__text {
  display: none;
}
@media (min-width: 840px) {
  .phone__text,
  .mail__text {
    display: inline;
  }
}
.phone {
  gap: 0 1rem;
}
.phone__ico {
  width: 2.2rem;
  height: 2.2rem;
}
.mail {
  gap: 0 1.8rem;
}
.mail__ico {
  width: 2.8rem;
  height: 2.2rem;
}
.navBg {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background: rgba(19,19,19,0.8);
  transition: width 0s 0s, opacity 0.5s 0.01s;
}
.navBg.hiden {
  width: 0;
  opacity: 0;
  transition: width 0s 0.5s, opacity 0.5s 0.01s;
}
.nav {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  background: #1b6ecf;
  max-width: 48rem;
  width: 100%;
  padding: 6rem 2rem 2rem;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  transition-duration: 0.5s;
  line-height: 1.2;
}
@media (min-width: 992px) {
  .nav {
    padding: min(calc(3.3rem + 1.72vw), 6.6rem) 2rem;
  }
}
.nav.hiden {
  transform: translate(100%, 0);
}
@media (min-width: 992px) {
  .nav {
    padding: 8rem 6rem;
  }
}
.nav__closer {
  position: absolute;
  top: 2rem;
  right: 2rem;
}
@media (min-width: 992px) {
  .nav__closer {
    right: 4rem;
  }
}
.nav__wrap {
  overflow: hidden;
  height: 100%;
}
.nav__box {
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: min(calc(2rem + 1.04vw), 4rem) 0;
  margin-right: -30px;
  padding-right: 30px;
}
@media (min-width: 992px) {
  .nav__link {
    border-bottom: 1px solid rgba(255,255,255,0);
    transition-duration: 0.32s;
  }
}
@media (hover: hover) and (min-width: 992px) {
  .nav__link:hover {
    border-bottom: 1px solid rgba(255,255,255,0.75);
  }
}
.nav__link.active {
  border-bottom: 1px solid rgba(255,255,255,0.75);
}
.logo__img--white {
  display: none;
}
.logo__img--dark {
  display: block;
}
.footer {
  position: relative;
  z-index: 1;
  background: #434141;
  color: #fff;
  line-height: 1.3;
  letter-spacing: 0.025em;
}
.footer__box {
  padding-top: min(calc(2.1rem + 1.09vw), 4.2rem);
  padding-bottom: min(calc(4rem + 2.08vw), 8rem);
  display: grid;
  grid-gap: min(calc(2.4rem + 1.25vw), 4.8rem) 4%;
  grid-template-columns: 100%;
}
@media (min-width: 480px) {
  .footer__box {
    grid-template-columns: repeat(2, 48%);
  }
}
@media (min-width: 992px) {
  .footer__box {
    grid-gap: min(calc(1.6rem + 0.83vw), 3.2rem) 6%;
    grid-template-columns: repeat(4, 20.5%);
  }
}
@media (min-width: 480px) {
  .footer__box > *:nth-child(1) {
    grid-column: span 2;
  }
}
@media (min-width: 1280px) {
  .footer__box > *:nth-child(1) {
    grid-row: span 2;
    grid-column: span 1;
  }
}
.footer__box > *:nth-child(2) {
  grid-row: 3/4;
}
@media (min-width: 480px) {
  .footer__box > *:nth-child(2) {
    grid-column: 1/3;
  }
}
@media (min-width: 992px) {
  .footer__box > *:nth-child(2) {
    grid-row: 2/2;
    grid-column: 1/3;
  }
}
@media (min-width: 1280px) {
  .footer__box > *:nth-child(2) {
    grid-row: span 1;
    grid-column: span 1;
  }
}
@media (min-width: 480px) {
  .footer__box > *:nth-child(3) {
    grid-column: span 2;
  }
}
@media (min-width: 992px) {
  .footer__box > *:nth-child(3) {
    grid-row: span 2;
  }
}
@media (min-width: 1280px) {
  .footer__box > *:nth-child(-n+3) .footer__title {
    padding: 0 0 min(calc(2.1rem + 1.09vw), 4.2rem);
  }
}
.footer__title {
  font-weight: 600;
  font-size: min(calc(1.7rem + 0.89vw), 3.4rem);
  padding: 0 0 min(calc(0.7rem + 0.36vw), 1.4rem);
}
@media (min-width: 992px) {
  .footer__title {
    font-size: min(calc(1.4rem + 0.73vw), 2.8rem);
  }
}
.footer__copy {
  border-top: 0.1rem solid rgba(206,206,206,0.29);
  text-align: center;
  padding: min(calc(1.15rem + 0.6vw), 2.3rem) 2rem min(calc(1.3rem + 0.68vw), 2.6rem);
  font-size: min(calc(1.4rem + 0.1vw), 1.6rem);
  color: #969696;
}
.srv {
  display: flex;
  gap: min(calc(0.8rem + 0.42vw), 1.6rem) 4%;
  flex-wrap: wrap;
}
@media (min-width: 992px) {
  .srv {
    gap: min(calc(0.8rem + 0.42vw), 1.6rem) 6.6%;
  }
}
.srv__item {
  width: 100%;
}
@media (min-width: 560px) {
  .srv__item {
    width: 48%;
  }
}
@media (min-width: 992px) {
  .srv__item {
    width: 46.7%;
  }
}
.soc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: min(calc(11.8rem + 6.15vw), 23.6rem);
}
@media (min-width: 992px) {
  .soc {
    max-width: min(calc(10rem + 5.21vw), 20rem);
  }
}
.soc__item {
  transition: transform 0.32s;
}
@media (hover: hover) and (min-width: 992px) {
  .soc__item:hover {
    transform: scale(1.1);
  }
}
.form {
  display: flex;
  gap: min(calc(1rem + 0.52vw), 2rem) min(calc(2.15rem + 1.12vw), 4.3rem);
  flex-direction: column;
}
@media (min-width: 1040px) {
  .form {
    flex-direction: row;
  }
}
.form__answerSend,
.form__answerError {
  display: none;
  font-weight: 600;
  text-align: center;
}
.form__answerError {
  color: #ff8080;
}
.form__input {
  border: 0.2rem solid #fff;
  font-size: min(calc(1.4rem + 0.21vw), 1.8rem);
  padding: min(calc(0.65rem + 0.34vw), 1.3rem);
  border-radius: 2.5rem;
  color: #fff;
  background: transparent;
  width: 100%;
  transition: all 0.32s;
}
@media (min-width: 540px) {
  .form__input {
    max-width: min(calc(19rem + 9.9vw), 38rem);
  }
}
@media (min-width: 1040px) {
  .form__input {
    max-width: min(calc(12.5rem + 6.51vw), 25rem);
  }
}
.form__input::placeholder {
  text-align: center;
  color: #fff;
}
.form__input--btn {
  color: #262626;
  background: #fff;
}
.form__input.error {
  border-color: #ff8080;
}
.form__input.disabled,
.form__input.disabled:hover {
  opacity: 0.35;
}
.form__loader {
  display: none;
  width: 10rem;
  height: 10rem;
  margin-left: auto;
  margin-right: auto;
}
.form__answerSend,
.form__answerError {
  padding: 0;
}
.imgLoader {
  position: absolute;
  width: 10rem;
  height: 10rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  stroke: #fff;
}
.sliderSwiper {
  position: relative;
  padding: 0 min(calc(2.5rem + 1.3vw), 5rem);
}
@media (min-width: 760px) {
  .sliderSwiper {
    padding: 0 min(calc(2rem + 1.04vw), 4rem);
  }
}
@media (min-width: 760px) {
  .sliderSwiper--reviews {
    padding: 0 min(calc(3rem + 1.56vw), 6rem);
  }
}
.sliderSwiper__arrow {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  width: min(calc(3rem + 1.56vw), 6rem);
  cursor: pointer;
  transition-duration: 0.32s;
}
.sliderSwiper__arrow--left {
  left: 0;
}
.sliderSwiper__arrow--right {
  right: 0;
}
.sliderSwiper__icoArrow {
  position: absolute;
  top: 50%;
  transform: translate(0.3rem, -50%) scale(0.6);
  width: 2.6rem;
  height: 3rem;
}
@media (min-width: 760px) {
  .sliderSwiper__icoArrow {
    transform: translate(0.1rem, -50%) scale(0.6);
  }
}
@media (min-width: 992px) {
  .sliderSwiper__icoArrow {
    transform: translate(0.1rem, -50%);
  }
}
.sliderSwiper__icoArrow--objects {
  top: 65%;
}
.sliderSwiper__arrow--right {
  justify-content: flex-end;
}
.sliderSwiper__arrow--right .sliderSwiper__icoArrow {
  transform: rotate(180deg) translate(0.3rem, 50%) scale(0.6);
}
@media (min-width: 760px) {
  .sliderSwiper__arrow--right .sliderSwiper__icoArrow {
    transform: rotate(180deg) translate(0.1rem, 50%) scale(0.6);
  }
}
@media (min-width: 992px) {
  .sliderSwiper__arrow--right .sliderSwiper__icoArrow {
    transform: rotate(180deg) translate(0.1rem, 50%);
  }
}
.sliderSwiper__wrap {
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  touch-action: pan-y;
}
.sliderSwiper__inner {
  display: flex;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  transition-property: transform;
}
.sliderSwiper__slide {
  flex-shrink: 0;
}
.content:before {
  content: "";
  display: block;
  position: relative;
}
.content:before,
.content__fixed {
  min-height: min(calc(23.9rem + 12.45vw), 47.8rem);
}
.content--obj:before,
.content--obj .content__fixed {
  min-height: min(calc(10.25rem + 5.34vw), 20.5rem);
}
.content--index:before,
.content--index .content__fixed {
  height: 100vh;
}
.content__fixed {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background-position: 50% 50%;
  background-size: cover;
}
.content__fixed:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(5,7,47,0.51);
}
.content--index .content__fixed:before {
  background: rgba(1,13,30,0.51);
}
.content__box {
  width: 100%;
}
.content__inner {
  position: relative;
  z-index: 2;
  background: #fff;
}
.content__title {
  color: #fff;
  font-size: min(calc(1.4rem + 2.6vw), 6.4rem);
  text-transform: uppercase;
  padding: min(calc(12rem + 6.25vw), 24rem) 0 min(calc(2.5rem + 1.3vw), 5rem);
  line-height: 1.2;
  position: relative;
  z-index: 2;
  font-weight: 700;
}
@media (min-width: 480px) {
  .content__title {
    padding: min(calc(12rem + 6.25vw), 24rem) 0 min(calc(2.5rem + 1.3vw), 5rem);
  }
}
@media (min-width: 992px) {
  .content__title {
    padding: min(calc(12rem + 6.25vw), 24rem) 0 min(calc(2.5rem + 1.3vw), 5rem);
  }
}
@media (min-width: 1560px) {
  .content__title {
    padding: min(calc(9rem + 4.69vw), 18rem) 0 min(calc(2.5rem + 1.3vw), 5rem);
  }
}
.mainSlider {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  position: relative;
  background: #000;
}
.mainSlider__box {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 4;
}
.mainSlider__area {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4rem;
  cursor: pointer;
  z-index: 4;
}
@media (min-width: 992px) {
  .mainSlider__area {
    width: 18rem;
  }
}
.mainSlider__area--left {
  left: 0;
}
.mainSlider__area--left.beforeHide {
  transform: translate(50px, 0);
}
.mainSlider__area--right {
  right: 0;
}
.mainSlider__area--right.beforeHide {
  transform: translate(-50px, 0);
}
.mainSlider__arr {
  width: 3.6rem;
  height: 3.6rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: relative;
  border-radius: 50%;
}
@media (min-width: 992px) {
  .mainSlider__arr {
    width: 7.6rem;
    height: 7.6rem;
  }
}
.mainSlider__arr--left {
  transform: translate(-50%, -50%) rotate(180deg);
}
@media (min-width: 992px) {
  .mainSlider__arr:before,
  .mainSlider__arr:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border: 1px solid rgba(255,255,255,0.75);
    background: rgba(0,0,0,0.05);
    box-shadow: 0 0 1rem rgba(0,0,0,0.05);
  }
}
.mainSlider__arr:before {
  opacity: 0;
  transform: scale(0.7);
}
@media (hover: hover) and (min-width: 992px) {
  .mainSlider__area:hover .mainSlider__arr:before,
  .mainSlider__area:hover .mainSlider__arr:after {
    transition-duration: 0.5s;
  }
}
@media (hover: hover) and (min-width: 992px) {
  .mainSlider__area:hover .mainSlider__arr:before {
    opacity: 1;
    transform: scale(1);
  }
}
@media (hover: hover) and (min-width: 992px) {
  .mainSlider__area:hover .mainSlider__arr:after {
    transform: scale(1.5);
    opacity: 0;
  }
}
.mainSlider__arrInner:before,
.mainSlider__arrInner:after {
  content: '';
  position: absolute;
  right: 30%;
  width: 30%;
  height: 0.2rem;
  background: #fff;
  transition-duration: 0.5s;
}
.mainSlider__arrInner:before {
  top: 40%;
  margin-top: -1px;
  transform: rotate(45deg);
}
.mainSlider__arrInner:after {
  bottom: 40%;
  margin-bottom: -1px;
  transform: rotate(-45deg);
}
@media (hover: hover) and (min-width: 992px) {
  .mainSlider__area:hover .mainSlider__arrInner:before {
    transform: rotate(-135deg);
  }
}
@media (hover: hover) and (min-width: 992px) {
  .mainSlider__area:hover .mainSlider__arrInner:after {
    transform: rotate(135deg);
  }
}
.mainSlider__text {
  position: relative;
  margin: auto;
  max-width: 154rem;
  padding: 0 4rem;
  width: 100%;
  font-weight: 600;
  font-size: min(calc(1.4rem + 3.33vw), 7.8rem);
  letter-spacing: 0.02rem;
  line-height: 1.2;
  color: #fff;
  z-index: 3;
  text-shadow: 0 0 1rem rgba(0,0,0,0.7);
  text-transform: uppercase;
  transition-duration: 0.5s;
}
@media (min-width: 992px) {
  .mainSlider__text {
    padding: 0 18rem;
  }
}
.mainSlider__text.hiden {
  opacity: 0;
  transform: translate(0, -3.5rem);
}
.mainSlider__slide {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transform: translate(100%, 0);
  overflow: hidden;
  transform: translate(0, 0);
  transition-duration: 0.5s;
}
.mainSlider__slide.before {
  transform: translate(100%, 0);
}
.mainSlider__bg,
.mainSlider__img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition-duration: 0.5s;
}
.mainSlider__bg {
  filter: blur(7px);
  transform: scale(1);
}
.mainSlider__img {
  z-index: 1;
}
.mainSlider__slide.after .mainSlider__bg {
  transform: scale(1.1);
}
.mainSlider__slide.after .mainSlider__img {
  transform: scale(0.8);
}
.benefits {
  max-width: 170rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  font-size: min(calc(1.4rem + 0.94vw), 3.2rem);
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .benefits {
    font-size: min(calc(1.4rem + 0.31vw), 2rem);
  }
}
@media (min-width: 1120px) {
  .benefits {
    border-bottom: 0.1rem solid #cecece;
  }
}
.benefits__item {
  width: 100%;
  flex-shrink: 0;
  flex-direction: column;
  padding: 5.7rem 2rem 2.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem 0;
  overflow: hidden;
  border-bottom: 0.1rem solid #cecece;
}
@media (min-width: 768px) {
  .benefits__item {
    width: 50%;
  }
}
@media (min-width: 1120px) {
  .benefits__item {
    width: 25%;
    border-bottom: 0;
  }
}
.benefits__item:nth-child(n+2) {
  border-left: 0.1rem solid #cecece;
}
@media (min-width: 992px) {
  .benefits__img,
  .benefits__text {
    transition-duration: 0.32s;
  }
}
.benefits__img {
  transform: scale(0.8) translate(0, -33%);
}
@media (min-width: 992px) {
  .benefits__img {
    transform: none;
  }
}
.benefits__text {
  opacity: 1;
  transform: translate(0, -75%);
}
@media (min-width: 992px) {
  .benefits__text {
    opacity: 0;
    transform: translate(0, 100%);
  }
}
@media (hover: hover) and (min-width: 992px) {
  .benefits__item:hover .benefits__text {
    opacity: 1;
    transform: translate(0, -75%);
  }
}
@media (hover: hover) and (min-width: 992px) {
  .benefits__item:hover .benefits__img {
    transform: scale(0.8) translate(0, -33%);
  }
}
.spec {
  background: #f7f7f9;
  line-height: 1.38;
}
.spec__item {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 992px) {
  .spec__item {
    min-height: min(calc(29.5rem + 15.36vw), 59rem);
  }
}
.spec__item:nth-child(even) {
  flex-direction: row-reverse;
}
.spec__img,
.spec__box {
  flex-shrink: 0;
  width: 100%;
}
@media (min-width: 768px) {
  .spec__img,
  .spec__box {
    width: 50%;
  }
}
.spec__box {
  display: flex;
  flex-direction: column;
  gap: min(calc(0.8rem + 0.42vw), 1.6rem) 0;
  padding: 2rem 2rem 6rem;
  justify-content: center;
}
@media (min-width: 768px) {
  .spec__box {
    gap: min(calc(1.5rem + 0.78vw), 3rem) 0;
  }
}
@media (min-width: 992px) {
  .spec__box {
    padding: min(calc(1.15rem + 0.6vw), 2.3rem) min(calc(4.9rem + 2.55vw), 9.8rem) min(calc(3.65rem + 1.9vw), 7.3rem);
  }
}
.spec__img {
  background-position: 50%;
  background-size: cover;
  position: relative;
  height: min(calc(12.5rem + 6.51vw), 25rem);
}
@media (min-width: 768px) {
  .spec__img {
    height: auto;
  }
}
.spec__img:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(5,7,47,0.3);
}
.spec__title {
  font-size: min(calc(1.4rem + 1.46vw), 4.2rem);
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .spec__title {
    font-size: min(calc(1.4rem + 0.83vw), 3rem);
  }
}
.slider {
  padding: min(calc(2.2rem + 1.15vw), 4.4rem) 0 min(calc(0.5rem + 0.26vw), 1rem);
  position: relative;
}
.slider__title {
  padding-bottom: min(calc(0.3rem + 0.16vw), 0.6rem);
}
@media (min-width: 760px) {
  .slider__wrap {
    background: #f7f7f9;
  }
}
.slider__boxSiblings {
  padding: min(calc(1.2rem + 0.63vw), 2.4rem) min(calc(2.5rem + 1.3vw), 5rem);
}
@media (min-width: 760px) {
  .slider__boxSiblings {
    padding: min(calc(1.2rem + 0.63vw), 2.4rem) min(calc(3rem + 1.56vw), 6rem);
  }
}
@media (min-width: 992px) {
  .slider__boxSiblings {
    padding: min(calc(1.2rem + 0.63vw), 2.4rem) calc(5rem + min(calc(3rem + 1.56vw), 6rem));
  }
}
.slider__box {
  padding-right: 0;
  padding-left: 0;
  margin: 0 auto;
}
@media (min-width: 992px) {
  .slider__box {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}
.objectSlide {
  display: flex;
  flex-direction: column;
  gap: min(calc(1.25rem + 0.65vw), 2.5rem) 0;
  line-height: 1.2;
  padding: min(calc(1.15rem + 0.6vw), 2.3rem) 0;
  font-size: min(calc(1.4rem + 0.31vw), 2rem);
  transition-duration: 0.32s;
}
@media (min-width: 760px) {
  .objectSlide {
    padding: min(calc(2.15rem + 1.12vw), 4.3rem) min(calc(1.15rem + 0.6vw), 2.3rem);
  }
}
.objectSlide.swiper-slide-next {
  background: #fff;
}
@media (hover: hover) and (min-width: 992px) {
  .objectSlide:hover {
    background: #deedff;
  }
}
.objectSlide__title {
  font-weight: 600;
  min-height: min(calc(2.4rem + 1.25vw), 4.8rem);
}
.objectSlide__text {
  font-size: min(calc(1.4rem + 0.1vw), 1.6rem);
}
.objectSlide__link {
  display: flex;
  align-items: flex-end;
  line-height: 0.9;
  gap: 0 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0.3rem 0;
}
.objectSlide__ico {
  width: 1.7rem;
  height: 1.7rem;
  fill: #1b6ecf;
}
.objectSlide__img {
  height: 100%;
  max-height: 290px;
  object-fit: cover;
}
.reviewSlide {
  position: relative;
}
.reviewSlide:before,
.reviewSlide__ico {
  opacity: 0;
  transition-duration: 0.32s;
}
.reviewSlide:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255,0.5);
}
.reviewSlide:hover:before,
.reviewSlide:hover .reviewSlide__ico {
  opacity: 1;
}
.reviewSlide__ico {
  width: 3.5rem;
  height: 3.5rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.reviewSlide__img {
  border: 0.1rem solid #aaa;
  height: 100%;
}
.newsSlide {
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  padding: min(calc(1rem + 0.52vw), 2rem) 0;
}
@media (min-width: 760px) {
  .newsSlide {
    padding: min(calc(1rem + 0.52vw), 2rem);
  }
}
.newsSlide__date {
  padding: 0 0 min(calc(1.2rem + 0.63vw), 2.4rem);
  font-weight: 600;
  color: #1b6ecf;
}
.newsSlide__title {
  text-decoration: underline;
  text-transform: uppercase;
  font-size: min(calc(1.4rem + 0.73vw), 2.8rem);
  transition: all 0.32s;
}
.newsSlide:hover .newsSlide__title {
  color: #1b6ecf;
  text-decoration-color: transparent;
}
.sectionGray {
  background: #f7f7f9;
}
.sectionText {
  padding: min(calc(2.85rem + 1.48vw), 5.7rem) 0 min(calc(3.7rem + 1.93vw), 7.4rem);
}
.sectionText__text {
  max-width: 75rem;
}
.feedback {
  color: #fff;
  line-height: 1.2;
  padding-bottom: min(calc(1.5rem + 0.78vw), 3rem);
}
.feedback__box {
  position: relative;
  padding-top: min(calc(4rem + 2.08vw), 8rem);
  min-height: min(calc(22rem + 11.46vw), 44rem);
}
@media (min-width: 1040px) {
  .feedback__box {
    padding-top: min(calc(9rem + 4.69vw), 18rem);
  }
}
.feedback__img {
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: min(calc(14.85rem + 7.73vw), 29.7rem);
}
@media (min-width: 540px) {
  .feedback__img {
    display: block;
  }
}
@media (min-width: 1340px) {
  .feedback__img {
    left: min(calc(8.5rem + 4.43vw), 17rem);
  }
}
.feedback__wrap {
  position: relative;
  background-image: url("../images/feedback_bg.jpg");
  background-position: 50% 50%;
  background-size: cover;
  border-radius: min(calc(0.75rem + 0.39vw), 1.5rem);
  overflow: hidden;
  padding: min(calc(1.1rem + 0.57vw), 2.2rem) 2rem min(calc(1.6rem + 0.83vw), 3.2rem);
  min-height: 26rem;
  text-align: center;
}
@media (min-width: 540px) {
  .feedback__wrap {
    text-align: left;
    padding: min(calc(1.1rem + 0.57vw), 2.2rem) 0 min(calc(1.6rem + 0.83vw), 3.2rem) min(calc(15rem + 7.81vw), 30rem);
  }
}
@media (min-width: 1040px) {
  .feedback__wrap {
    min-height: min(calc(13rem + 6.77vw), 26rem);
  }
}
@media (min-width: 1340px) {
  .feedback__wrap {
    padding: min(calc(1.1rem + 0.57vw), 2.2rem) 0 min(calc(1.6rem + 0.83vw), 3.2rem) min(calc(25.4rem + 13.23vw), 50.8rem);
  }
}
.feedback__wrap:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(27,110,207,0.85);
}
.feedback__title,
.feedback__text,
.feedback__form {
  position: relative;
  z-index: 1;
}
.feedback__title {
  font-size: min(calc(1.4rem + 0.73vw), 2.8rem);
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: min(calc(0.35rem + 0.18vw), 0.7rem);
}
.feedback__form {
  padding-top: min(calc(1.85rem + 0.96vw), 3.7rem);
}
.sectionTeplo1__box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 840px) {
  .sectionTeplo1__box {
    flex-direction: row;
  }
}
.sectionTeplo1__column {
  width: 100%;
  padding: min(calc(2rem + 1.04vw), 4rem) 0;
  flex-shrink: 0;
}
@media (min-width: 840px) {
  .sectionTeplo1__column {
    width: 50%;
    padding: min(calc(2.4rem + 1.25vw), 4.8rem) min(calc(2rem + 1.04vw), 4rem) min(calc(2rem + 1.04vw), 4rem) 0;
  }
}
.sectionTeplo1__text.text {
  font-size: min(calc(1.4rem + 0.31vw), 2rem);
}
@media (min-width: 840px) {
  .sectionTeplo1__text {
    padding-right: min(calc(2rem + 1.04vw), 4rem);
  }
}
.sectionTeplo1__sub {
  font-weight: 600;
  text-transform: uppercase;
  padding: min(calc(2.4rem + 1.25vw), 4.8rem) 0 min(calc(1.6rem + 0.83vw), 3.2rem);
  max-width: 41.5rem;
  width: 100%;
  font-size: min(calc(1.4rem + 0.31vw), 2rem);
  line-height: 1.2;
}
.sectionTeplo1__btns {
  display: flex;
  gap: min(calc(1.5rem + 0.78vw), 3rem);
  flex-wrap: wrap;
}
.sectionTeplo1__lnk {
  font-size: min(calc(1.4rem + 0.16vw), 1.7rem);
  font-weight: 400;
}
@media (min-width: 840px) {
  .sectionTeplo1__img {
    width: 50%;
  }
}
.sectionTeplo2__box {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
@media (min-width: 840px) {
  .sectionTeplo2__box {
    flex-direction: row;
  }
}
.sectionTeplo2__column {
  width: 100%;
  padding-bottom: min(calc(1.45rem + 0.76vw), 2.9rem);
}
@media (min-width: 840px) {
  .sectionTeplo2__column {
    width: 50%;
    flex-shrink: 0;
    padding-bottom: min(calc(2rem + 1.04vw), 4rem);
  }
}
@media (min-width: 840px) {
  .sectionTeplo2__column:nth-child(2) {
    padding-right: min(calc(3rem + 1.56vw), 6rem);
  }
}
@media (min-width: 1240px) {
  .sectionTeplo2__column:nth-child(2) {
    padding-right: min(calc(10rem + 5.21vw), 20rem);
  }
}
.sectionTeplo2__title {
  width: 100%;
  padding: min(calc(3.4rem + 1.77vw), 6.8rem) 0 min(calc(1.6rem + 0.83vw), 3.2rem);
  font-size: min(calc(1.4rem + 1.25vw), 3.8rem);
}
.sectionVodo1__box {
  display: flex;
}
.sectionVodo1__column {
  position: relative;
}
.sectionVodo1__column:nth-child(1) {
  width: 100%;
  flex-shrink: 0;
  padding: min(calc(2.7rem + 1.41vw), 5.4rem) min(calc(2.15rem + 1.12vw), 4.3rem) min(calc(1.65rem + 0.86vw), 3.3rem) 0;
}
@media (min-width: 840px) {
  .sectionVodo1__column:nth-child(1) {
    width: 50%;
  }
}
@media (min-width: 1180px) {
  .sectionVodo1__column:nth-child(1) {
    width: 61%;
  }
}
.sectionVodo1__title {
  padding: 0 0 min(calc(1.35rem + 0.7vw), 2.7rem);
  max-width: 65rem;
}
.sectionVodo1__img {
  width: 100%;
  object-fit: cover;
}
@media (min-width: 840px) {
  .sectionVodo1__img {
    width: 50%;
  }
}
@media (min-width: 1180px) {
  .sectionVodo1__img {
    width: 39%;
  }
}
.sectionVodo1__text {
  max-width: 65rem;
}
.sectionVodo1__text.text {
  font-size: min(calc(1.4rem + 0.31vw), 2rem);
}
.sectionVodo2__column:nth-child(1) {
  width: 100%;
  flex-shrink: 0;
  padding-right: min(calc(2.15rem + 1.12vw), 4.3rem);
}
@media (min-width: 840px) {
  .sectionVodo2__column:nth-child(1) {
    width: 50%;
  }
}
@media (min-width: 1180px) {
  .sectionVodo2__column:nth-child(1) {
    width: 61%;
  }
}
.sectionVodo2__title {
  padding: 0 0 min(calc(1.35rem + 0.7vw), 2.7rem);
  max-width: 65rem;
}
.sectionVodo2__text {
  max-width: 64rem;
}
.sectionVodo2__text.text {
  font-size: min(calc(1.4rem + 0.31vw), 2rem);
}
.sectionProject__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: min(calc(2.4rem + 1.25vw), 4.8rem);
  padding-bottom: min(calc(2rem + 1.04vw), 4rem);
  align-items: flex-start;
}
@media (min-width: 840px) {
  .sectionProject__box {
    padding-top: min(calc(3.85rem + 2.01vw), 7.7rem);
    flex-direction: row;
  }
}
.sectionProject__column {
  width: 100%;
  padding: 0 0 min(calc(2rem + 1.04vw), 4rem);
  flex-shrink: 0;
}
@media (min-width: 840px) {
  .sectionProject__column {
    width: 50%;
    padding: 0 min(calc(2rem + 1.04vw), 4rem) 0 0;
  }
}
.sectionProject__title {
  padding: 0 0 min(calc(1.35rem + 0.7vw), 2.7rem);
}
.sectionProject__text.text {
  font-size: min(calc(1.4rem + 0.31vw), 2rem);
}
.sectionProject__text {
  max-width: 70rem;
}
@media (min-width: 840px) {
  .sectionProject__text {
    padding-right: min(calc(2rem + 1.04vw), 4rem);
  }
}
@media (min-width: 840px) {
  .sectionProject__img {
    width: 50%;
  }
}
.sectionProject__img2 {
  width: 50%;
}
@media (min-width: 1280px) {
  .sectionProject__img2 {
    width: auto;
  }
}
.works {
  padding: min(calc(4.1rem + 2.14vw), 8.2rem) 0 min(calc(1.9rem + 0.99vw), 3.8rem);
}
@media (min-width: 840px) {
  .works {
    padding: min(calc(4.1rem + 2.14vw), 8.2rem) 0;
  }
}
.works__title {
  padding-bottom: min(calc(2.5rem + 1.3vw), 5rem);
}
.works__items {
  display: flex;
  flex-wrap: wrap;
  gap: min(calc(3rem + 1.56vw), 6rem) 4%;
}
@media (min-width: 992px) {
  .works__items {
    gap: min(calc(4rem + 2.08vw), 8rem) 3.5%;
  }
}
@media (min-width: 1440px) {
  .works__items {
    gap: min(calc(4rem + 2.08vw), 8rem) 2%;
  }
}
.works__item {
  display: flex;
  gap: 0 min(calc(1rem + 0.52vw), 2rem);
  width: 100%;
  font-size: min(calc(1.4rem + 0.21vw), 1.8rem);
  line-height: 1.2;
}
@media (min-width: 640px) {
  .works__item {
    max-width: 48%;
  }
}
@media (min-width: 992px) {
  .works__item {
    max-width: 31%;
  }
}
@media (min-width: 1440px) {
  .works__item {
    max-width: 23.5%;
  }
}
.works__num {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #1b6ecf;
  font-size: min(calc(1.4rem + 1.15vw), 3.6rem);
  font-style: italic;
  border-radius: 50%;
  width: min(calc(3.15rem + 1.64vw), 6.3rem);
  height: min(calc(3.15rem + 1.64vw), 6.3rem);
  flex-shrink: 0;
  padding-right: min(calc(0.4rem + 0.21vw), 0.8rem);
}
.sectionList {
  padding: min(calc(1.85rem + 0.96vw), 3.7rem) 0;
}
@media (min-width: 680px) {
  .sectionList {
    padding: min(calc(1.85rem + 0.96vw), 3.7rem) 0 min(calc(0.65rem + 0.34vw), 1.3rem);
  }
}
.sectionList__title {
  padding-bottom: min(calc(1.9rem + 0.99vw), 3.8rem);
}
.sectionList__list {
  display: flex;
  flex-wrap: wrap;
  border-top: 0;
  line-height: 1.2;
  position: relative;
}
@media (min-width: 680px) {
  .sectionList__list {
    border-top: 0.1rem solid #adadad;
  }
}
.sectionList__list--type2 {
  padding-bottom: min(calc(0.6rem + 0.31vw), 1.2rem);
}
@media (min-width: 680px) {
  .sectionList__list--type2:before {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: min(calc(0.6rem + 0.31vw), 1.2rem);
    height: 0.1rem;
    left: 0;
    right: 0;
    background: #fff;
  }
}
.sectionList__item {
  position: relative;
  border-bottom: 0.1rem solid #adadad;
  text-transform: uppercase;
  padding: min(calc(0.85rem + 0.44vw), 1.7rem) min(calc(2.5rem + 1.3vw), 5rem) min(calc(0.85rem + 0.44vw), 1.7rem) min(calc(1.4rem + 0.73vw), 2.8rem);
  width: 100%;
  font-weight: 500;
}
@media (min-width: 680px) {
  .sectionList__item {
    width: 50%;
  }
}
@media (min-width: 1080px) {
  .sectionList__item {
    width: 33.333%;
  }
}
.sectionList__item:before {
  content: "●";
  position: absolute;
  top: min(calc(0.8rem + 0.42vw), 1.6rem);
  left: min(calc(0.35rem + 0.18vw), 0.7rem);
  line-height: 1.2;
  font-size: min(calc(1.4rem + -0.1vw), 1.2rem);
}
@media (min-width: 992px) {
  .sectionList__item:before {
    top: min(calc(0.85rem + 0.44vw), 1.7rem);
    font-size: min(calc(1.4rem + 0vw), 1.4rem);
    line-height: 1.4;
  }
}
.sectionList__item:last-child {
  border-bottom: 0;
}
@media (min-width: 680px) {
  .sectionList__item:last-child {
    border-bottom: 0.1rem solid #adadad;
  }
}
@media (min-width: 1280px) {
  .sectionList__item--type1:nth-child(5n+1) {
    width: 27%;
  }
}
@media (min-width: 1280px) {
  .sectionList__item--type1:nth-child(5n+2) {
    width: 21%;
  }
}
@media (min-width: 1280px) {
  .sectionList__item--type1:nth-child(5n+3) {
    width: 19%;
  }
}
@media (min-width: 1280px) {
  .sectionList__item--type1:nth-child(5n+4) {
    width: 16%;
  }
}
@media (min-width: 1280px) {
  .sectionList__item--type1:nth-child(5) {
    width: 17%;
  }
}
.sectionList__item--type1:last-child {
  width: 100%;
}
@media (min-width: 1280px) {
  .sectionList__item--type1:last-child {
    width: 33%;
  }
}
.sectionList__item--type2 {
  font-weight: 400;
}
.sectionList__item--type2:before {
  display: none;
}
@media (min-width: 1080px) {
  .sectionList__item--type2:nth-child(3n+1),
  .sectionList__item--type2:nth-child(3n+2) {
    width: 35%;
  }
}
@media (min-width: 1080px) {
  .sectionList__item--type2:nth-child(3n+3) {
    width: 30%;
  }
}
.sectionList__ico {
  position: absolute;
  left: 0;
  top: min(calc(0.95rem + 0.49vw), 1.9rem);
  width: 1.7rem;
  height: 1.7rem;
  fill: #1b6ecf;
  transform: scale(0.7);
}
@media (min-width: 1080px) {
  .sectionList__ico {
    transform: none;
  }
}
.sectionNews__nav {
  border-bottom: 1px solid #7b7b7b;
  padding: min(calc(4.4rem + 2.29vw), 8.8rem) 0 min(calc(1.6rem + 0.83vw), 3.2rem);
  display: flex;
  flex-wrap: wrap;
  gap: min(calc(1rem + 0.52vw), 2rem) min(calc(0.6rem + 0.31vw), 1.2rem);
}
.sectionNews__navItem {
  display: flex;
  gap: 0 min(calc(0.6rem + 0.31vw), 1.2rem);
  text-transform: uppercase;
  transition: all 0.32s;
}
@media (hover: hover) and (min-width: 992px) {
  .sectionNews__navItem:hover {
    color: #1b6ecf;
  }
}
.sectionNews__navItem:nth-child(n+2):before {
  content: '/';
}
.sectionNews__navItem:nth-child(n+2):hover:before {
  color: #262626;
}
.sectionNews__navItem.active {
  cursor: default;
  font-weight: 600;
}
.sectionNews__list {
  display: flex;
  flex-wrap: wrap;
  gap: min(calc(3.1rem + 1.61vw), 6.2rem) 6%;
  padding: min(calc(2.8rem + 1.46vw), 5.6rem) 0 min(calc(3.25rem + 1.69vw), 6.5rem);
}
@media (min-width: 992px) {
  .sectionNews__list {
    gap: min(calc(4.4rem + 2.29vw), 8.8rem) 6.5%;
  }
}
.sectionNews__item {
  width: 100%;
  padding: 0;
}
@media (min-width: 520px) {
  .sectionNews__item {
    width: 47%;
  }
}
@media (min-width: 760px) {
  .sectionNews__item {
    padding: 0;
  }
}
@media (min-width: 992px) {
  .sectionNews__item {
    width: 29%;
  }
}
.sectionShort__box {
  padding-top: min(calc(3.25rem + 1.69vw), 6.5rem);
  padding-bottom: min(calc(3.25rem + 1.69vw), 6.5rem);
  max-width: 68rem;
}
.sectionShort__text.text {
  font-size: min(calc(1.4rem + 0.21vw), 1.8rem);
  font-weight: 500;
}
.sectionObj__nav {
  border-bottom: 1px solid #7b7b7b;
  padding: min(calc(2.8rem + 1.46vw), 5.6rem) 0 min(calc(2.15rem + 1.12vw), 4.3rem);
  display: flex;
  gap: min(calc(1.75rem + 0.91vw), 3.5rem) 6%;
  text-transform: uppercase;
  justify-content: space-between;
  line-height: 1.2;
  flex-wrap: wrap;
}
@media (min-width: 992px) {
  .sectionObj__nav {
    gap: min(calc(1.75rem + 0.91vw), 3.5rem) 6.5%;
  }
}
@media (min-width: 1540px) {
  .sectionObj__nav {
    flex-wrap: nowrap;
    gap: 0 min(calc(2.75rem + 1.43vw), 5.5rem);
  }
}
.sectionObj__navItem {
  transition: all 0.32s;
  width: 100%;
}
@media (min-width: 520px) {
  .sectionObj__navItem {
    width: 47%;
  }
}
@media (min-width: 992px) {
  .sectionObj__navItem {
    width: 29%;
  }
}
@media (min-width: 1540px) {
  .sectionObj__navItem {
    width: auto;
  }
}
@media (hover: hover) and (min-width: 992px) {
  .sectionObj__navItem:hover {
    color: #1b6ecf;
  }
}
.sectionObj__navItem--active {
  font-weight: 600;
}
.sectionObj__list {
  display: flex;
  flex-wrap: wrap;
  padding: min(calc(2.8rem + 1.46vw), 5.6rem) 0;
  gap: min(calc(4.4rem + 2.29vw), 8.8rem) 6%;
}
@media (min-width: 992px) {
  .sectionObj__list {
    gap: min(calc(4.4rem + 2.29vw), 8.8rem) 6.5%;
  }
}
.sectionObj__item {
  width: 100%;
  padding: 0;
  justify-content: space-between;
}
@media (hover: hover) and (min-width: 992px) {
  .sectionObj__item:hover {
    background: transparent;
    transform: scale(1.05);
    color: #1b6ecf;
  }
}
@media (min-width: 520px) {
  .sectionObj__item {
    width: 47%;
  }
}
@media (min-width: 760px) {
  .sectionObj__item {
    padding: 0;
  }
}
@media (min-width: 992px) {
  .sectionObj__item {
    width: 29%;
  }
}
.pagesNav {
  padding: min(calc(1rem + 0.52vw), 2rem) 0 min(calc(2.75rem + 1.43vw), 5.5rem);
  display: flex;
  gap: min(calc(1rem + 0.52vw), 2rem) min(calc(1.5rem + 0.78vw), 3rem);
  justify-content: center;
}
.pagesNav__item {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: min(calc(1.7rem + 0.89vw), 3.4rem);
  min-height: min(calc(1.7rem + 0.89vw), 3.4rem);
  border-radius: 50%;
  border: 0.1rem solid transparent;
  transition: all 0.32s;
  font-weight: 600;
}
@media (hover: hover) and (min-width: 992px) {
  .pagesNav__item:hover {
    color: #fff;
    background: #1b6ecf;
  }
}
.pagesNav__item--active {
  border-color: #1b6ecf;
  color: #1b6ecf;
  cursor: default;
}
@media (hover: hover) and (min-width: 992px) {
  .pagesNav__item--active:hover {
    background: #fff;
    color: #1b6ecf;
  }
}
.objPg {
  padding: min(calc(3.5rem + 1.82vw), 7rem) 0;
}
.objPg__box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 0 5%;
}
.objPg__title {
  padding-bottom: min(calc(3rem + 1.56vw), 6rem);
}
.objPg__subTitle.title {
  padding-bottom: min(calc(0.9rem + 0.47vw), 1.8rem);
  font-size: min(calc(1.4rem + 0.42vw), 2.2rem);
  text-transform: none;
}
@media (min-width: 1080px) {
  .objPg__subTitle.title {
    font-size: min(calc(1.4rem + 0.73vw), 2.8rem);
  }
}
.objPg__title,
.objPg__linkWrap {
  width: 100%;
}
.objPg__text {
  font-size: min(calc(1.4rem + 0.31vw), 2rem);
}
.objPg__left {
  width: 100%;
  order: 1;
}
@media (min-width: 1080px) {
  .objPg__left {
    width: 44%;
    order: inherit;
  }
}
.objPg__right {
  width: 100%;
  padding-bottom: min(calc(4.4rem + 2.29vw), 8.8rem);
}
@media (min-width: 1080px) {
  .objPg__right {
    width: 51%;
    padding-bottom: 0;
  }
}
.objPg__linkWrap {
  padding-top: min(calc(6rem + 3.13vw), 12rem);
  order: 2;
}
@media (min-width: 1080px) {
  .objPg__linkWrap {
    order: inherit;
  }
}
.objPg__link {
  display: flex;
  position: relative;
  text-transform: uppercase;
  align-items: center;
  gap: 0 min(calc(0.85rem + 0.44vw), 1.7rem);
  font-size: min(calc(1.4rem + 0.31vw), 2rem);
  font-weight: 700;
  transition: all 0.32s;
}
.objPg__link:hover {
  color: #1b6ecf;
}
.objPg__icoArrow {
  width: 2.6rem;
  height: 3rem;
}
.objPg__slider1 {
  padding: 0 0 min(calc(1.5rem + 0.78vw), 3rem);
}
@media (min-width: 760px) {
  .objPg__slider1 {
    padding: 0 0 min(calc(1.5rem + 0.78vw), 3rem);
  }
}
.objPg__slider2 {
  padding: 0;
}
@media (min-width: 760px) {
  .objPg__slider2 {
    padding: 0;
  }
}
.objPg__slider1Img {
  width: 100%;
  max-height: 48rem;
  object-fit: contain;
}
.objPg__slider2Img {
  object-fit: cover;
  height: 100%;
  max-height: 20rem;
  width: 100%;
}
.columns {
  display: flex;
  flex-direction: column;
}
@media (min-width: 840px) {
  .columns {
    flex-direction: row;
  }
}
.column {
  position: relative;
  padding-top: min(calc(2.7rem + 1.41vw), 5.4rem);
  padding-bottom: min(calc(1.65rem + 0.86vw), 3.3rem);
}
.columnNum {
  position: absolute;
  left: 0;
  top: 0;
  background: #1b6ecf;
  padding: min(calc(0.35rem + 0.18vw), 0.7rem) min(calc(0.85rem + 0.44vw), 1.7rem);
  min-width: min(calc(2.75rem + 1.43vw), 5.5rem);
  color: #fff;
  text-align: center;
  font-weight: 700;
  font-size: min(calc(1.4rem + 0.31vw), 2rem);
}
.popupText {
  max-width: 100rem;
  padding: min(calc(2.35rem + 1.22vw), 4.7rem) min(calc(3.6rem + 1.88vw), 7.2rem);
}
.popupText__title {
  padding-bottom: min(calc(1.55rem + 0.81vw), 3.1rem);
  text-transform: none;
}
.text {
  display: flex;
  flex-direction: column;
  gap: min(calc(1.4rem + 0.73vw), 2.8rem) 0;
  font-size: min(calc(1.4rem + 0.52vw), 2.4rem);
  line-height: 1.2;
}
.text ul {
  display: flex;
  flex-direction: column;
  gap: min(calc(1.4rem + 0.73vw), 2.8rem) 0;
  padding-left: 3.1rem;
}
.text ul li:before {
  content: "●";
  position: absolute;
  top: 0;
  left: min(calc(-1.2rem + -0.62vw), -2.4rem);
  line-height: 1.5;
  font-size: min(calc(1.4rem + 0.1vw), 1.6rem);
}
.text li {
  position: relative;
}
.fancybox-slide--html .fancybox-close-small {
  padding: 0;
  top: 1rem;
  right: 1rem;
}
.digits {
  background-size: cover;
  background-position: 50% 50%;
  background-image: url("../images/main_bg.jpg");
  position: relative;
}
@media (min-width: 992px) {
  .digits {
    height: min(calc(54.05rem + 28.15vw), 108.1rem);
  }
}
.digits:before,
.digits:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background: rgba(1,13,30,0.51);
}
.digits:after {
  background: rgba(255,255,255,0.75);
  z-index: 1;
}
@media (min-width: 992px) {
  .digits:after {
    display: none;
  }
}
.digits__text {
  line-height: 1.38;
}
@media (min-width: 992px) {
  .digits__text {
    line-height: 1.2;
  }
}
.digits__textWrap {
  position: relative;
  z-index: 2;
  width: 100%;
  padding: min(calc(3.35rem + 1.74vw), 6.7rem) 2rem 0;
  display: flex;
  flex-direction: column;
  gap: min(calc(1.95rem + 1.02vw), 3.9rem) 0;
  margin: 0 auto;
}
@media (min-width: 992px) {
  .digits__textWrap {
    max-width: 52rem;
    padding: min(calc(3.35rem + 1.74vw), 6.7rem) 0 0;
    right: 2rem;
    top: 0;
    position: absolute;
    z-index: 3;
    margin: 0;
  }
}
@media (min-width: 1240px) {
  .digits__textWrap {
    max-width: 64rem;
  }
}
@media (min-width: 1440px) {
  .digits__textWrap {
    left: 50%;
    right: auto;
  }
}
.digits__text.text {
  font-size: min(calc(1.4rem + 0.21vw), 1.8rem);
}
.digits__wrap {
  position: relative;
  z-index: 2;
  padding: min(calc(1.4rem + 0.73vw), 2.8rem) 0 min(calc(0.7rem + 0.36vw), 1.4rem);
}
@media (min-width: 992px) {
  .digits__wrap {
    padding: 0;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}
.digits__items {
  text-align: center;
}
@media (min-width: 992px) {
  .digits__items {
    margin-top: 94px;
    position: absolute;
    width: 254rem;
    height: 290rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg) scale(1.0004);
    border: 100rem solid rgba(255,255,255,0.75);
    display: grid;
    grid-template-columns: repeat(3, 18rem);
    grid-template-rows: repeat(5, 18rem);
  }
}
@media (min-width: 1440px) {
  .digits__items {
    transform: translate(-50%, -50%) rotate(-45deg) scale(1.02);
    width: 278rem;
    height: 330rem;
    grid-template-columns: repeat(3, 26rem);
    grid-template-rows: repeat(5, 26rem);
  }
}
@media (min-width: 992px) {
  .squr {
    position: relative;
    border: 0.5rem solid rgba(255,255,255,0.75);
    box-shadow: 0 0 1px 0 rgba(255,255,255,0.75);
    color: #fff;
  }
}
@media (min-width: 1440px) {
  .squr {
    border-width: 1rem;
  }
}
.squr--hover {
  border-top: 0.1rem solid rgba(0,0,0,0.5);
  padding: min(calc(1.1rem + 0.57vw), 2.2rem) 2rem;
}
@media (min-width: 992px) {
  .squr--hover {
    border-top: 0.5rem solid rgba(255,255,255,0.75);
  }
}
@media (min-width: 1440px) {
  .squr--hover {
    border-top: 1rem solid rgba(255,255,255,0.75);
  }
}
@media (hover: hover) and (min-width: 992px) {
  .squr--hover:before {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.32s;
    background-color: #1b6ecf;
  }
}
@media (hover: hover) and (min-width: 992px) {
  .squr--hover:hover:before {
    opacity: 1;
  }
}
@media (min-width: 992px) {
  .squr--empty {
    background: rgba(255,255,255,0.75);
    border: 0;
  }
}
.squr:nth-child(1) {
  display: none;
}
@media (min-width: 992px) {
  .squr:nth-child(1) {
    display: block;
    grid-row: 1/3;
    grid-column: 1/3;
  }
}
.squr:nth-child(2) {
  grid-row: 1/4;
  grid-column: 3/4;
}
.squr:nth-child(3) {
  grid-row: 3/4;
}
.squr:nth-child(4) {
  grid-row: 3/4;
}
.squr:nth-child(5) {
  grid-row: 4/5;
  grid-column: 2/3;
}
.squr:nth-child(6) {
  grid-row: 4/5;
  grid-column: 3/4;
}
.squr:nth-child(9) {
  grid-row: 5/6;
  grid-column: 3/4;
}
.squr:nth-child(8) {
  grid-column: 1/3;
}
@media (min-width: 992px) {
  .squr__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(45deg);
    height: 100%;
    position: relative;
    z-index: 1;
  }
}
.squr__title,
.squr__dgt {
  font-size: min(calc(1.4rem + 2.29vw), 5.8rem);
}
@media (min-width: 1440px) {
  .squr__title,
  .squr__dgt {
    font-size: min(calc(1.4rem + 3.02vw), 7.2rem);
  }
}
.squr__title {
  font-weight: 700;
}
.squr__dgt {
  display: none;
  font-weight: 600;
  transition: opacity 0.32s;
  opacity: 1;
}
@media (min-width: 992px) {
  .squr__dgt {
    display: block;
  }
}
@media (hover: hover) and (min-width: 992px) {
  .squr--hover:hover .squr__dgt {
    opacity: 0;
  }
}
.squr__txt {
  display: flex;
  flex-direction: column;
  gap: min(calc(0.3rem + 0.16vw), 0.6rem) 0;
  text-transform: uppercase;
  line-height: 1.2;
  font-size: min(calc(1.4rem + 0.52vw), 2.4rem);
}
@media (min-width: 992px) {
  .squr__txt {
    gap: min(calc(0.65rem + 0.34vw), 1.3rem) 0;
    font-size: min(calc(1.4rem + 0vw), 1.4rem);
    opacity: 0;
    position: absolute;
    z-index: 2;
    transition: opacity 0.32s;
  }
}
@media (min-width: 1440px) {
  .squr__txt {
    font-weight: 600;
    font-size: min(calc(1.4rem + 0.21vw), 1.8rem);
  }
}
.squr__txt p:nth-child(1) {
  font-weight: 700;
}
@media (min-width: 992px) {
  .squr__txt p:nth-child(1) {
    font-weight: inherit;
  }
}
@media (hover: hover) and (min-width: 992px) {
  .squr--hover:hover .squr__txt {
    opacity: 1;
  }
}
