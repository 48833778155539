html
  font-size 62.5%
  scroll-behavior smooth

body
  color #262626
  fs(18)
  font-weight 400
  min-width 32rem
  height 100%
  line-height 1
  font-family $fontGilroy

.cursorWait
  cursor wait

.noscript
  position fixed
  display flex
  justify-content center
  align-items center
  width 100%
  height 100%
  padding 0 2rem
  line-height 1.2
  background-color #fff
  z-index 999

.lnk
  background #1b6ecf
  color #fff
  font-weight: 700
  padding 1.5rem
  fill #fff
  display inline-flex
  align-items center
  gap 0 3.4rem
  text-transform uppercase
  letter-spacing .05rem
  position relative
  fs(20)
  border .1rem solid #1b6ecf
  transition-duration .32s

  &:before
    content ''
    position absolute
    top 0
    bottom 0
    width .1rem
    background #fff
    right 5rem
    transition-duration .32s

  &:hover
    @media (hover: hover) and (min-width: $widthMobile)
      background #fff
      color #1b6ecf
      fill #1b6ecf

  &:hover:before
    @media (hover: hover) and (min-width: $widthMobile)
      background #1b6ecf

  &__ico
    width: 1.7rem
    height: 1.7rem

.box
  margin 0 auto
  padding-left 2rem
  padding-right 2rem
  max-width 180rem

  @media (min-width: $widthMobile)
    padding-left 5rem
    padding-right 5rem

.title
  fs(42)
  line-height 1.2
  font-weight 700
  letter-spacing .1rem
  text-transform uppercase

  @media (min-width: $widthMobile)
    fs(28)

.beforeHide
  opacity 0
  transition-duration .55s
  transform translate(0, -3.5rem)





/* HEADER */

.header
  position fixed
  top 0
  left 0
  width 100%
  z-index 8
  color #262626
  box-shadow 0 0 .8rem rgba(0,0,0,.75)
  fs(22)
  font-weight 600
  transition-duration .32s
  background #fff
  fill #262626

  &--transparent
    color #fff
    fill #fff
    text-shadow 0 0 1rem rgba(0,0,0,.35)
    box-shadow none
    background transparent

    .openNav
      &:before,
      &:after,
      &__inner
        background #fff

    .logo__img
      &--dark
        display none

      &--white
        display block

  &__box
    display flex
    align-items center
    flex-wrap wrap
    padding-top 1.8rem
    padding-bottom 1.8rem
    z-index 2
    gap 1rem 0

    @media (min-width: 840px)
      flex-wrap nowrap

  &--transparent &__box
    @media (min-width: 1120px)
      padding-top 4.1rem

  &__divide
    width 100%
    
    @media (min-width: 840px)
      display none

  &__logo
    max-width 18rem
    flex-shrink 0

    @media (min-width: 1120px)
      max-width 30.2rem

  &__phone
    order 1

    @media (min-width: 840px)
      order inherit
      padding-left: cw(82)

  &__mail
    order 1
    padding-left: cw(42)
    
    @media (min-width: 840px)
      order inherit
      display flex
      justify-content flex-start
      padding-left: cw(66)

  &__linkNav
    margin-left: auto

.openNav
  width 5rem
  height 3rem
  position relative
  cursor pointer

  @media (min-width: 1120px)
    width 6.5rem
    height 4.4rem

  &:before,
  &:after,
  &__inner
    position absolute
    background #262626
    width 100%
    height .4rem
    border-radius .3rem
    left 0

    @media (min-width: 1120px)
      height .6rem

  &:before,
  &:after
    content ''
    z-index 1
    transition top .32s .32s, bottom .32s .32s, margin .32s .32s, width 0s .32s, transform .32s 0s

  &:before
    top 0

  &:after
    bottom 0

  &__inner
    top 50%
    transform translate(0, -50%)

  &:hover:before,
  &:hover:after
    @media (hover: hover) and (min-width: $widthMobile)
      width 50%
      transition top .32s 0s, bottom .32s 0s, margin .32s 0s, width 0s .32s, transform .32s .32s

  &:hover:before
    @media (hover: hover) and (min-width: $widthMobile)
      top 50%
      margin -.2rem 0 0 0
      transform translate(-.4rem, -.8rem) rotate(-45deg)

    @media (min-width: 1120px)
      margin -.3rem 0 0 0
      transform translate(-.5rem, -1rem) rotate(-45deg)

  &:hover:after
    @media (hover: hover) and (min-width: $widthMobile)
      bottom 50%
      margin 0 0 -.2rem 0
      transform translate(-.4rem, .8rem)  rotate(45deg)

    @media (min-width: 1120px)
      margin 0 0 -.3rem 0
      transform translate(-.5rem, 1rem)  rotate(45deg)

.closeNav
  width 5rem
  height 5rem
  position relative
  cursor pointer

  @media (min-width: 1120px)
    width 6.5rem
    height 6.5rem

  &:before,
  &:after
    content ''
    transition transform .6s 0s, transform-origin 0s 175ms, width 0s 175ms;

  &:before,
  &:after,
  &__inner
    position absolute
    background #fff
    width 100%
    height .4rem
    border-radius .2rem
    right 0
    top 50%

    @media (min-width: 1120px)
      height .6rem
      border-radius .3rem
    
  &__inner
    opacity 0
    transition opacity 0s 175ms

  &:before
    transform rotate(-45deg)

  &:after
    transform rotate(45deg)

  &:hover &__inner
    @media (hover: hover) and (min-width: $widthMobile)
      opacity 1

  &:hover:before,
  &:hover:after
    @media (hover: hover) and (min-width: $widthMobile)
      width 50%
      transform-origin 100% center
      transition transform .6s 0s, transform-origin 0s 175ms, width 0s 175ms;

  &:hover:before
    @media (hover: hover) and (min-width: $widthMobile)
      transform rotate(45deg)

  &:hover:after
    @media (hover: hover) and (min-width: $widthMobile)
      transform rotate(-45deg)

.phone,
.mail
  display flex
  align-items center
  white-space nowrap

  &__text
    display none

    @media (min-width: 840px)
      display inline

.phone
  gap 0 1rem

  &__ico
    width 2.2rem
    height 2.2rem

.mail
  gap 0 1.8rem

  &__ico
    width 2.8rem
    height 2.2rem

.navBg
  position: fixed
  top: 0
  right: 0
  z-index 9
  width 100%
  height 100%
  background rgba(19,19,19,.8)
  transition width 0s 0s, opacity .5s .01s

  &.hiden
    width 0
    opacity 0
    transition width 0s .5s, opacity .5s .01s

.nav
  position: fixed
  right 0
  top 0
  bottom 0
  z-index 10
  background #1B6ECF
  max-width 48rem
  width 100%
  padding 6rem 2rem 2rem
  font-weight 600
  color #fff
  text-transform uppercase
  transition-duration .5s
  line-height 1.2

  @media (min-width: $widthMobile)
    padding cw(66) 2rem

  &.hiden
    transform translate(100%,0)

  @media (min-width: $widthMobile)
    padding 8rem 6rem

  &__closer
    position absolute
    top 2rem
    right 2rem

    @media (min-width: $widthMobile)
      right 4rem

  &__wrap
    overflow hidden
    height: 100%

  &__box
    height: 100%
    overflow-y auto
    display flex
    flex-direction column
    gap cw(40) 0
    margin-right: -30px;
    padding-right: 30px;

  &__link
    @media (min-width: $widthMobile)
      border-bottom: 1px solid rgba(255,255,255,0)
      transition-duration: .32s

    &:hover
      @media (hover: hover) and (min-width: $widthMobile)
        border-bottom: 1px solid rgba(255,255,255,.75)

    &.active
      border-bottom: 1px solid rgba(255,255,255,.75)

.logo__img
  &--white
    display none

  &--dark
    display block





/* FOOTER */

.footer
  position relative
  z-index 1
  background #434141
  color #fff
  line-height 1.3
  letter-spacing .025em

  &__box
    padding-top cw(42)
    padding-bottom cw(80)
    display grid
    grid-gap cw(48) 4%
    grid-template-columns 100%

    @media (min-width: 480px)
      grid-template-columns repeat(2, 48%)

    @media (min-width: $widthMobile)
      grid-gap cw(32) 6%
      grid-template-columns repeat(4, 20.5%)

    & > *:nth-child(1)
      @media (min-width: 480px)
        grid-column span 2

      @media (min-width: 1280px)
        grid-row span 2
        grid-column span 1

    & > *:nth-child(2)
      grid-row 3 / 4

      @media (min-width: 480px)
        grid-column 1 / 3

      @media (min-width: $widthMobile)
        grid-row 2 / 2
        grid-column 1 / 3

      @media (min-width: 1280px)
        grid-row span 1
        grid-column span 1

    & > *:nth-child(3)
      @media (min-width: 480px)
        grid-column span 2

      @media (min-width: $widthMobile)
        grid-row span 2

  &__box > *:nth-child(-n+3) &__title
    @media (min-width: 1280px)
      padding 0 0 cw(42)

  &__title
    font-weight 600
    font-size cw(34)
    padding 0 0 cw(14)
    
    @media (min-width: $widthMobile)
      font-size cw(28)

  &__copy
    border-top .1rem solid rgba(206, 206, 206, 0.29)
    text-align center
    padding cw(23) 2rem cw(26)
    fs(16)
    color #969696

.srv
  display flex
  gap cw(16) 4%
  flex-wrap wrap

  @media (min-width: $widthMobile)
    gap cw(16) 6.6%

  &__item
    width 100%

    @media (min-width: 560px)
      width 48%

    @media (min-width: $widthMobile)
      width 46.7%

.soc
  display: flex
  align-items center
  justify-content: space-between
  max-width cw(236)

  @media (min-width: $widthMobile)
    max-width cw(200)

  &__item
    transition: transform .32s

    &:hover
      @media (hover: hover) and (min-width: $widthMobile)
        transform scale(1.1)






/* FORM */

.form
  display flex
  gap cw(20) cw(43)
  flex-direction column

  @media (min-width: 1040px)
    flex-direction row
    
  &__answerSend,
  &__answerError
    display none
    font-weight 600
    text-align center

  &__answerError
    color #ff8080

  &__input
    border .2rem solid #fff
    fs(18)
    padding cw(13)
    border-radius 2.5rem
    color #fff
    background: transparent
    width 100%
    transition all .32s

    @media (min-width: 540px)
      max-width cw(380)
      
    @media (min-width: 1040px)
      max-width cw(250)

    &::placeholder
      text-align center
      color #fff

    &--btn
      color #262626
      background #fff

    &.error
      border-color #ff8080

    &.disabled,
    &.disabled:hover
      opacity .35

  &__loader
    display none
    width 10rem
    height 10rem
    margin-left auto
    margin-right auto

  &__answerSend,
  &__answerError
    padding 0

.imgLoader
  position absolute
  width 10rem
  height 10rem
  left 50%
  top 50%
  transform translate(-50%,-50%)
  stroke #fff






/* SWIPER */

.sliderSwiper
  position relative
  padding 0 cw(50)

  @media (min-width: 760px)
    padding 0 cw(40)

  &--reviews
    @media (min-width: 760px)
      padding 0 cw(60)

  &__arrow
    position absolute
    display flex
    align-items center
    top 0
    bottom 0
    width cw(60)
    cursor pointer
    transition-duration .32s

    &--left
      left 0

    &--right
      right 0

  &__icoArrow
    position absolute
    top 50%
    transform translate(.3rem, -50%) scale(.6)
    width 2.6rem
    height 3rem

    @media (min-width: 760px)
      transform translate(.1rem, -50%) scale(.6)

    @media (min-width: $widthMobile)
      transform translate(.1rem, -50%)

    &--objects
      top 65%

  &__arrow--right
    justify-content flex-end

  &__arrow--right &__icoArrow
    transform rotate(180deg) translate(.3rem, 50%) scale(.6)

    @media (min-width: 760px)
      transform rotate(180deg) translate(.1rem, 50%) scale(.6)

    @media (min-width: $widthMobile)
      transform rotate(180deg) translate(.1rem, 50%)

  &__wrap
    position: relative;
    z-index: 1;
    overflow: hidden;
    width: 100%;
    touch-action: pan-y;

  &__inner
    display: flex;
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    transition-property transform

  &__slide
    flex-shrink: 0





/* CONTENT */

.content
  &:before
    content ""
    display block
    position relative

  &:before,
  &__fixed
    min-height cw(478)

  &--obj:before,
  &--obj &__fixed
    min-height cw(205)

  &--index:before,
  &--index &__fixed
    height 100vh

  &__fixed
    position fixed
    width 100%
    top 0
    left 0
    z-index 1
    background-position 50% 50%
    background-size cover

    &:before
      content ""
      position absolute
      left 0
      top 0
      width 100%
      height 100%
      z-index 2
      background rgba(5, 7, 47, 0.51)

  &--index &__fixed:before
    background rgba(1, 13, 30, .51)

  &__box
    width 100%

  &__inner
    position relative
    z-index 2
    background #fff

  &__title
    color #fff
    fs(64)
    text-transform uppercase
    padding cw(240) 0 cw(50)
    line-height 1.2
    position relative
    z-index 2
    font-weight 700

    @media (min-width: 480px)
      padding cw(240) 0 cw(50)

    @media (min-width: $widthMobile)
      padding cw(240) 0 cw(50)

    @media (min-width: 1560px)
      padding cw(180) 0 cw(50)

.mainSlider
  height 100%
  width 100%
  overflow hidden
  display flex
  position relative
  background #000

  &__box
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    left: 50%;
    transform: translate(-50%,0);
    z-index: 4

  &__area
    position absolute
    top 0
    bottom 0
    width 4rem
    cursor pointer
    z-index 4
    
    @media (min-width: $widthMobile)
      width 18rem

    &--left
      left 0

      &.beforeHide
        transform translate(50px,0)

    &--right
      right 0

      &.beforeHide
        transform translate(-50px,0)

  &__arr
    width 3.6rem
    height 3.6rem
    top 50%
    left 50%
    transform translate(-50%,-50%)
    position relative
    border-radius 50%
    
    @media (min-width: $widthMobile)
      width 7.6rem
      height 7.6rem

    &--left
      transform translate(-50%,-50%) rotate(180deg)

    &:before,
    &:after
      @media (min-width: $widthMobile)
        content ''
        position absolute
        left 0
        top 0
        right 0
        bottom 0
        border-radius 50%
        border 1px solid rgba(255,255,255,.75);
        background rgba(0,0,0,.05)
        box-shadow 0 0 1rem rgba(0,0,0,.05)

    &:before
      opacity 0
      transform scale(.7)

  &__area:hover &__arr:before,
  &__area:hover &__arr:after
    @media (hover: hover) and (min-width: $widthMobile)
      transition-duration: .5s;

  &__area:hover &__arr:before
    @media (hover: hover) and (min-width: $widthMobile)
      opacity 1
      transform scale(1)

  &__area:hover &__arr:after
    @media (hover: hover) and (min-width: $widthMobile)
      transform scale(1.5)
      opacity 0

  &__arrInner
    &:before,
    &:after
      content ''
      position absolute
      right: 30%;
      width: 30%;
      height: .2rem
      background #fff
      transition-duration: .5s;
      
    &:before
      top: 40%;
      margin-top: -1px;
      transform: rotate(45deg);

    &:after
      bottom: 40%;    
      margin-bottom: -1px;
      transform: rotate(-45deg);

  &__area:hover &__arrInner:before
    @media (hover: hover) and (min-width: $widthMobile)
      transform: rotate(-135deg);

  &__area:hover &__arrInner:after
    @media (hover: hover) and (min-width: $widthMobile)
      transform: rotate(135deg);

  &__text
    position relative
    margin auto
    max-width 154rem
    padding 0 4rem
    width 100%
    font-weight 600
    fs(78)
    letter-spacing .02rem
    line-height 1.2
    color #fff
    z-index 3
    text-shadow 0 0 1rem rgba(0,0,0,.7)
    text-transform uppercase
    transition-duration: .5s
    
    @media (min-width: $widthMobile)
      padding 0 18rem

    &.hiden
      opacity 0
      transform translate(0, -3.5rem)

  &__slide
    position absolute
    left 0
    top 0
    right 0
    bottom 0
    transform translate(100%,0)
    overflow hidden
    transform translate(0,0)
    transition-duration .5s

    &.before
      transform translate(100%,0)

  &__bg,
  &__img
    position absolute
    left 0
    top 0
    width 100%
    height 100%
    object-fit cover
    transition-duration .5s

  &__bg
    filter blur(7px)
    transform scale(1)

  &__img
    z-index 1

  &__slide.after &__bg
    transform scale(1.1)

  &__slide.after &__img
    transform scale(.8)

.benefits
  max-width 170rem
  margin 0 auto
  display flex
  flex-wrap wrap
  fs(32)
  font-weight 500
  text-transform uppercase
  text-align center
  line-height 1.2

  @media (min-width: 768px)
    fs(20)
    
  @media (min-width: 1120px)
    border-bottom .1rem solid #CECECE

  &__item
    width 100%
    flex-shrink 0
    flex-direction column
    padding 5.7rem 2rem 2.2rem 
    display flex
    justify-content center
    align-items center
    gap 1.5rem 0
    overflow hidden
    border-bottom .1rem solid #CECECE
    
    @media (min-width: 768px)
      width 50%
    
    @media (min-width: 1120px)
      width 25%
      border-bottom 0

    &:nth-child(n+2)
      border-left .1rem solid #CECECE

  &__img,
  &__text
    @media (min-width: $widthMobile)
      transition-duration .32s

  &__img
    transform scale(.8) translate(0, -33%)

    @media (min-width: $widthMobile)
      transform none

  &__text
    opacity 1
    transform translate(0, -75%)

    @media (min-width: $widthMobile)
      opacity 0
      transform translate(0, 100%)

  &__item:hover &__text
    @media (hover: hover) and (min-width: $widthMobile)
      opacity 1
      transform translate(0, -75%)

  &__item:hover &__img
    @media (hover: hover) and (min-width: $widthMobile)
      transform scale(.8) translate(0, -33%)

.spec
  background #F7F7F9
  line-height 1.38

  &__item
    display flex
    flex-wrap wrap

    @media (min-width: $widthMobile)
      min-height cw(590)

    &:nth-child(even)
      flex-direction row-reverse

  &__img,
  &__box
    flex-shrink 0
    width 100%

    @media (min-width: 768px)
      width 50%

  &__box
    display flex
    flex-direction column
    gap cw(16) 0
    padding 2rem 2rem 6rem
    justify-content center

    @media (min-width: 768px)
      gap cw(30) 0
    
    @media (min-width: $widthMobile)
      padding cw(23) cw(98) cw(73)

  &__img
    background-position 50%
    background-size cover
    position relative
    height cw(250)

    @media (min-width: 768px)
      height auto

    &:before
      content ''
      position: absolute
      left 0
      right 0
      top 0
      bottom 0
      background-color rgba(5, 7, 47, .3)

  &__title
    fs(42)
    text-transform uppercase

    @media (min-width: 768px)
      fs(30)

.slider
  padding cw(44) 0 cw(10)
  position relative

  &__title
    padding-bottom cw(6)

  &__wrap
    @media (min-width: 760px)
      background #F7F7F9

  &__boxSiblings
    padding cw(24) cw(50)

    @media (min-width: 760px)
      padding cw(24) cw(60)

    @media (min-width: $widthMobile)
      padding cw(24) calc(5rem + min(calc(3rem + 1.56vw), 6rem) )

  &__box
    padding-right 0
    padding-left 0
    margin 0 auto

    @media (min-width: $widthMobile)
      padding-left 5rem
      padding-right 5rem

.objectSlide
  display: flex
  flex-direction: column
  gap cw(25) 0
  line-height 1.2
  padding cw(23) 0
  fs(20)
  transition-duration: .32s

  @media (min-width: 760px)
    padding cw(43) cw(23)

  &.swiper-slide-next
    background #fff

  &:hover
    @media (hover: hover) and (min-width: $widthMobile)
      background #deedff

  &__title
    font-weight 600
    min-height cw(48)

  &__text
    fs(16)

  &__link
    display: flex;
    align-items: flex-end;
    line-height: .9;
    gap 0 1.4rem
    font-weight: 700
    text-transform uppercase
    padding: .3rem 0

  &__ico
    width 1.7rem
    height 1.7rem
    fill #1b6ecf

  &__img
    height 100%
    max-height 290px
    object-fit cover

.reviewSlide
  position relative

  &:before,
  &__ico
    opacity 0
    transition-duration .32s

  &:before
    content ''
    position absolute
    left 0
    top 0
    right 0
    bottom 0
    background rgba(255,255,255,.5)

  &:hover:before,
  &:hover &__ico
    opacity 1

  &__ico
    width 3.5rem
    height 3.5rem
    position absolute
    left 50%
    top 50%
    transform: translate(-50%, -50%)

  &__img
    border .1rem solid #aaa
    height 100%

.newsSlide
  display flex
  flex-direction column
  line-height 1.2
  padding cw(20) 0

  @media (min-width: 760px)
    padding cw(20)

  &__date
    padding 0 0 cw(24)
    font-weight 600
    color #1B6ECF

  &__title
    text-decoration underline
    text-transform uppercase
    fs(28)
    transition all .32s
  
  &:hover &__title
    color #1B6ECF
    text-decoration-color transparent

.sectionGray
  background #F7F7F9

.sectionText
  padding cw(57) 0 cw(74)

  &__text
    max-width 75rem

.feedback
  color #fff
  line-height 1.2
  padding-bottom cw(30)

  &__box
    position relative
    padding-top cw(80)
    min-height cw(440)

    @media (min-width: 1040px)
      padding-top cw(180)

  &__img
    display none
    position absolute
    left 0
    bottom 0
    z-index 1
    width cw(297)

    @media (min-width: 540px)
      display block

    @media (min-width: 1340px)
      left cw(170)

  &__wrap
    position relative
    background-image url('../images/feedback_bg.jpg')
    background-position 50% 50%
    background-size cover 
    border-radius cw(15)
    overflow hidden
    padding cw(22) 2rem cw(32)
    min-height 26rem
    text-align center

    @media (min-width: 540px)
      text-align left
      padding cw(22) 0 cw(32) cw(300)

    @media (min-width: 1040px)
      min-height cw(260)

    @media (min-width: 1340px)
      padding cw(22) 0 cw(32) cw(508)

    &:before
      content ''
      position absolute
      left 0
      top 0
      right 0
      bottom 0
      background: rgba(27, 110, 207, 0.85);

  &__title,
  &__text,
  &__form
    position relative
    z-index 1

  &__title
    fs(28)
    font-weight 700
    text-transform uppercase
    padding-bottom cw(7)

  &__form
    padding-top cw(37)

.sectionTeplo1
  &__box
    display flex
    flex-direction column
    align-items center

    @media (min-width: 840px)
      flex-direction row

  &__column
    width 100%
    padding cw(40) 0
    flex-shrink 0

    @media (min-width: 840px)
      width 50%
      padding cw(48) cw(40) cw(40) 0

  &__text.text
    fs(20)

  &__text
    @media (min-width: 840px)
      padding-right cw(40)
    
  &__sub
    font-weight 600
    text-transform: uppercase
    padding cw(48) 0 cw(32)
    max-width 41.5rem
    width 100%
    fs(20)
    line-height 1.2
    
  &__btns
    display flex
    gap cw(30)
    flex-wrap: wrap

  &__lnk
    fs(17)
    font-weight 400

  &__img
    @media (min-width: 840px)
      width 50%

.sectionTeplo2
  &__box
    display flex
    flex-direction column
    flex-wrap: wrap

    @media (min-width: 840px)
      flex-direction row

  &__column
    width 100%
    padding-bottom cw(29)

    @media (min-width: 840px)
      width 50%
      flex-shrink: 0
      padding-bottom cw(40)

    &:nth-child(2)
      @media (min-width: 840px)
        padding-right cw(60)

      @media (min-width: 1240px)
        padding-right cw(200)

  &__title
    width 100%
    padding cw(68) 0 cw(32)
    fs(38)

.sectionVodo1
  &__box
    display flex

  &__column
    position relative

    &:nth-child(1)
      width 100%
      flex-shrink: 0;
      padding cw(54) cw(43) cw(33) 0

      @media (min-width: 840px)
        width 50%

      @media (min-width: 1180px)
        width 61%

  &__title
    padding 0 0 cw(27)
    max-width 65rem

  &__img
    width 100%
    object-fit cover

    @media (min-width: 840px)
      width 50%

    @media (min-width: 1180px)
      width 39%

  &__text
    max-width 65rem

    &.text
      fs(20)

.sectionVodo2
  &__column
    &:nth-child(1)
      width 100%
      flex-shrink 0
      padding-right cw(43)

      @media (min-width: 840px)
        width 50%

      @media (min-width: 1180px)
        width 61%

  &__title
    padding 0 0 cw(27)
    max-width 65rem

  &__text
    max-width 64rem

    &.text
      fs(20)

.sectionProject
  &__box
    display flex
    flex-direction column
    align-items center
    padding-top cw(48)
    padding-bottom cw(40)
    align-items flex-start

    @media (min-width: 840px)
      padding-top cw(77)
      flex-direction row

  &__column
    width 100%
    padding 0 0 cw(40)
    flex-shrink 0

    @media (min-width: 840px)
      width 50%
      padding 0 cw(40) 0 0

  &__title
    padding 0 0 cw(27)

  &__text.text
    fs(20)

  &__text
    max-width 70rem

    @media (min-width: 840px)
      padding-right cw(40)

  &__img
    @media (min-width: 840px)
      width 50%

  &__img2
    width 50%

    @media (min-width: 1280px)
      width auto

.works
  padding cw(82) 0 cw(38)

  @media (min-width: 840px)
    padding cw(82) 0

  &__title
    padding-bottom cw(50)

  &__items
    display flex
    flex-wrap wrap
    gap cw(60) 4%

    @media (min-width: $widthMobile)
      gap cw(80) 3.5%

    @media (min-width: 1440px)
      gap cw(80) 2%

  &__item
    display flex
    gap 0 cw(20)
    width 100%
    fs(18)
    line-height 1.2

    @media (min-width: 640px)
      max-width 48%

    @media (min-width: $widthMobile)
      max-width 31%

    @media (min-width: 1440px)
      max-width 23.5%

  &__num
    display flex
    align-items center
    justify-content center
    border 2px solid #1B6ECF
    fs(36)
    font-style italic
    border-radius 50%
    width cw(63)
    height cw(63)
    flex-shrink 0
    padding-right cw(8)

.sectionList
  padding cw(37) 0

  @media (min-width: 680px)
    padding cw(37) 0 cw(13)

  &__title
    padding-bottom cw(38)

  &__list
    display flex
    flex-wrap wrap
    border-top 0
    line-height 1.2
    position relative

    @media (min-width: 680px)
      border-top .1rem solid #ADADAD

    &--type2
      padding-bottom cw(12)

      &:before
        @media (min-width: 680px)
          content ""
          position absolute
          z-index 1
          bottom cw(12)
          height .1rem
          left 0
          right 0
          background #fff

  &__item
    position relative
    border-bottom .1rem solid #ADADAD
    text-transform uppercase
    padding cw(17) cw(50) cw(17) cw(28)
    width 100%
    font-weight 500

    @media (min-width: 680px)
      width 50%

    @media (min-width: 1080px)
      width 33.333%

    &:before
      content "●"
      position absolute
      top cw(16)
      left cw(7)
      line-height 1.2
      fs(12)

      @media (min-width: $widthMobile)
        top cw(17)
        fs(14)
        line-height 1.4

    &:last-child
      border-bottom 0

      @media (min-width: 680px)
        border-bottom .1rem solid #ADADAD

    &--type1
      &:nth-child(5n+1)
        @media (min-width: 1280px)
          width 27%

      &:nth-child(5n+2)
        @media (min-width: 1280px)
          width 21%

      &:nth-child(5n+3)
        @media (min-width: 1280px)
          width 19%

      &:nth-child(5n+4)
        @media (min-width: 1280px)
          width 16%

      &:nth-child(5)
        @media (min-width: 1280px)
          width 17%

      &:last-child
        width 100%

        @media (min-width: 1280px)
          width 33%

    &--type2
      font-weight 400

      &:before
        display none

      &:nth-child(3n+1),
      &:nth-child(3n+2)
        @media (min-width: 1080px)
          width 35%

      &:nth-child(3n+3)
        @media (min-width: 1080px)
          width 30%

  &__ico
    position absolute
    left 0
    top cw(19)
    width 1.7rem
    height 1.7rem
    fill #1b6ecf
    transform scale(.7)

    @media (min-width: 1080px)
      transform none

.sectionNews
  &__nav
    border-bottom 1px solid #7B7B7B
    padding cw(88) 0 cw(32)
    display flex
    flex-wrap wrap
    gap cw(20) cw(12)

  &__navItem
    display flex
    gap 0 cw(12)
    text-transform uppercase
    transition all .32s

    &:hover
      @media (hover: hover) and (min-width: $widthMobile)
        color #1b6ecf
    
    &:nth-child(n+2):before
      content '/'

    &:nth-child(n+2):hover:before
      color #262626

    &.active
      cursor default
      font-weight 600

  &__list
    display flex
    flex-wrap wrap
    gap cw(62) 6%
    padding cw(56) 0 cw(65)

    @media (min-width: $widthMobile)
      gap cw(88) 6.5%

  &__item
    width 100%
    padding 0

    @media (min-width: 520px)
      width 47%

    @media (min-width: 760px)
      padding 0

    @media (min-width: $widthMobile)
      width 29%

.sectionShort
  &__box
    padding-top cw(65)
    padding-bottom cw(65)
    max-width 68rem

  &__text.text
    fs(18)
    font-weight 500
  
.sectionObj
  &__nav
    border-bottom 1px solid #7B7B7B
    padding cw(56) 0 cw(43)
    display flex
    gap cw(35) 6%
    text-transform uppercase
    justify-content space-between
    line-height 1.2
    flex-wrap wrap

    @media (min-width: $widthMobile)
      gap cw(35) 6.5%

    @media (min-width: 1540px)
      flex-wrap nowrap
      gap 0 cw(55)

    &Item
      transition all .32s
      width 100%

      @media (min-width: 520px)
        width 47%

      @media (min-width: $widthMobile)
        width 29%

      @media (min-width: 1540px)
        width auto

      &:hover
        @media (hover: hover) and (min-width: $widthMobile)
          color #1b6ecf

      &--active
        font-weight 600
        
  &__list
    display flex
    flex-wrap wrap
    padding cw(56) 0
    gap cw(88) 6%

    @media (min-width: $widthMobile)
      gap cw(88) 6.5%

  &__item
    width 100%
    padding 0
    justify-content space-between

    &:hover
      @media (hover: hover) and (min-width: $widthMobile)
        background transparent
        transform scale(1.05)
        color #1b6ecf

    @media (min-width: 520px)
      width 47%

    @media (min-width: 760px)
      padding 0

    @media (min-width: $widthMobile)
      width 29%

.pagesNav
  padding cw(20) 0 cw(55)
  display flex
  gap cw(20) cw(30)
  justify-content center

  &__item
    display flex
    align-items center
    justify-content center
    min-width cw(34)
    min-height cw(34)
    border-radius 50%
    border .1rem solid transparent
    transition all .32s
    font-weight 600

    &:hover
      @media (hover: hover) and (min-width: $widthMobile)
        color #fff
        background #1b6ecf

    &--active
      border-color #1b6ecf
      color #1b6ecf
      cursor default

      &:hover
        @media (hover: hover) and (min-width: $widthMobile)
          background #fff
          color #1b6ecf

.objPg
  padding cw(70) 0

  &__box
    display flex
    flex-wrap wrap
    width 100%
    gap 0 5%

  &__title
    padding-bottom cw(60)

  &__subTitle.title
    padding-bottom cw(18)
    fs(22)
    text-transform none

    @media(min-width: 1080px)
      fs(28)

  &__title,
  &__linkWrap
    width 100%

  &__text
    fs(20)

  &__left
    width 100%
    order 1

    @media(min-width: 1080px)
      width 44%
      order inherit

  &__right
    width 100%
    padding-bottom cw(88)

    @media(min-width: 1080px)
      width 51%
      padding-bottom 0

  &__linkWrap
    padding-top cw(120)
    order 2

    @media(min-width: 1080px)
      order inherit

  &__link
    display flex
    position relative
    text-transform uppercase
    align-items center
    gap 0 cw(17)
    fs(20)
    font-weight 700
    transition all .32s

    &:hover
      color #1b6ecf

  &__icoArrow
    width 2.6rem
    height 3rem

  &__slider1
    padding 0 0 cw(30)

    @media (min-width: 760px)
      padding 0 0 cw(30)

  &__slider2
    padding 0

    @media (min-width: 760px)
      padding 0

  &__slider1Img
    width 100%
    max-height 48rem
    object-fit: contain;

  &__slider2Img
    object-fit: cover;
    height: 100%;
    max-height: 20rem;
    width: 100%;

.columns
  display flex
  flex-direction column

  @media (min-width: 840px)
    flex-direction row

.column
  position relative
  padding-top cw(54)
  padding-bottom cw(33)

.columnNum
  position absolute
  left 0
  top 0
  background #1B6ECF
  padding cw(7) cw(17)
  min-width cw(55)
  color #fff
  text-align center
  font-weight 700
  fs(20)

.popupText
  max-width 100rem
  padding cw(47) cw(72)

  &__title
    padding-bottom cw(31)
    text-transform none

.text
  display flex
  flex-direction column
  gap cw(28) 0
  fs(24)
  line-height 1.2

  ul
    display flex
    flex-direction column
    gap cw(28) 0
    padding-left 3.1rem

    li
      &:before
        content "●"
        position absolute
        top 0
        left cw(-24)
        line-height 1.5
        fs(16)

  li
    position relative

.fancybox-slide--html .fancybox-close-small
  padding 0
  top 1rem
  right 1rem

.digits
  background-size cover
  background-position 50% 50%
  background-image:url(../images/main_bg.jpg)
  position relative
    
  @media (min-width: $widthMobile)
    height cw(1081)

  &:before,
  &:after
    content ''
    position absolute
    left 0
    right 0
    top 0
    bottom 0
    z-index 1
    background rgba(1,13,30,.51)

  &:after
    background rgba(255,255,255,.75)
    z-index 1
    
    @media (min-width: $widthMobile)
      display none

  &__text
    line-height 1.38
    
    @media (min-width: $widthMobile)
      line-height 1.2

  &__textWrap
    position relative
    z-index 2
    width 100%
    padding cw(67) 2rem 0
    display flex
    flex-direction column
    gap cw(39) 0
    margin 0 auto

    @media (min-width: $widthMobile)
      max-width 52rem
      padding cw(67) 0 0
      right 2rem
      top 0
      position absolute
      z-index 3
      margin 0

    @media (min-width: 1240px)
      max-width 64rem

    @media (min-width: 1440px)
      left 50%
      right auto

  &__text.text
    fs(18)

  &__wrap
    position relative
    z-index 2
    padding cw(28) 0 cw(14)
    
    @media (min-width: $widthMobile)
      padding 0
      overflow hidden
      position absolute
      left 0
      top 0
      right 0
      bottom 0
    
  &__items
    text-align center
    
    @media (min-width: $widthMobile)
      margin-top 94px
      position absolute
      width 254rem//200+54
      height 290rem//200+90
      left 50%
      top 50%
      transform translate(-50%, -50%) rotate(-45deg) scale(1.0004)
      border 100rem solid rgba(255,255,255,.75)
      display grid
      grid-template-columns repeat(3, 18rem)
      grid-template-rows repeat(5, 18rem)
    
    @media (min-width: 1440px)
      transform translate(-50%, -50%) rotate(-45deg) scale(1.02)
      width 278rem//200+78
      height 330rem//200+130
      grid-template-columns repeat(3, 26rem)
      grid-template-rows repeat(5, 26rem)

.squr
  @media (min-width: $widthMobile)
    position relative
    border .5rem solid rgba(255,255,255,.75)
    box-shadow 0 0 1px 0 rgba(255,255,255,.75)
    color #fff
    
  @media (min-width: 1440px)
    border-width 1rem

  &--hover
    border-top .1rem solid rgba(0,0,0,.5)
    padding cw(22) 2rem

    @media (min-width: $widthMobile)
      border-top .5rem solid rgba(255,255,255,.75)
    
    @media (min-width: 1440px)
      border-top 1rem solid rgba(255,255,255,.75)

  &--hover:before
    @media (hover: hover) and (min-width: $widthMobile)
      content ''
      position absolute
      z-index 1
      left 0
      right 0
      top 0
      bottom 0
      opacity 0
      transition opacity .32s
      background-color #1B6ECF

  &--hover:hover:before
    @media (hover: hover) and (min-width: $widthMobile)
      opacity 1

  &--empty
    @media (min-width: $widthMobile)
      background rgba(255,255,255,.75)
      border 0

  &:nth-child(1)
    display none

    @media (min-width: $widthMobile)
      display block
      grid-row 1/3
      grid-column 1/3

  &:nth-child(2)
    grid-row 1/4
    grid-column 3/4
    
  &:nth-child(3)
    grid-row 3/4

  &:nth-child(4)
    grid-row 3/4

  &:nth-child(5)
    grid-row 4/5
    grid-column 2/3

  &:nth-child(6)
    grid-row 4/5
    grid-column 3/4

  &:nth-child(9)
    grid-row 5/6
    grid-column 3/4

  &:nth-child(8)
    grid-column 1/3

  &__inner
    @media (min-width: $widthMobile)
      display flex
      justify-content center
      align-items center
      transform rotate(45deg)
      height 100%
      position relative
      z-index 1

  &__title,
  &__dgt
    fs(58)

    @media (min-width: 1440px)
      fs(72)

  &__title
    font-weight 700
    
  &__dgt
    display none
    font-weight 600
    transition opacity .32s
    opacity 1

    @media (min-width: $widthMobile)
      display block

  &--hover:hover &__dgt
    @media (hover: hover) and (min-width: $widthMobile)
      opacity 0

  &__txt
    display flex
    flex-direction column
    gap cw(6) 0
    text-transform uppercase
    line-height 1.2
    fs(24)

    @media (min-width: $widthMobile)
      gap cw(13) 0
      fs(14)
      opacity 0
      position absolute
      z-index 2
      transition opacity .32s

    @media (min-width: 1440px)
      font-weight 600
      fs(18)
    
    & p:nth-child(1)
      font-weight 700

      @media (min-width: $widthMobile)
        font-weight inherit

  &--hover:hover &__txt
    @media (hover: hover) and (min-width: $widthMobile)
      opacity 1