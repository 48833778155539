@font-face
  font-family 'Gilroy'
  font-style normal
  font-weight 400
  src local(''), url('../fonts/gilroy-regular.woff2') format('woff2'), url('../fonts/gilroy-regular.woff') format('woff')
  font-display swap

@font-face
  font-family 'Gilroy'
  font-style normal
  font-weight 500
  src local(''), url('../fonts/gilroy-medium.woff2') format('woff2'), url('../fonts/gilroy-medium.woff') format('woff')
  font-display swap

@font-face
  font-family 'Gilroy'
  font-style normal
  font-weight 600
  src local(''), url('../fonts/gilroy-semibold.woff2') format('woff2'), url('../fonts/gilroy-semibold.woff') format('woff')
  font-display swap

@font-face
  font-family 'Gilroy'
  font-style normal
  font-weight 700
  src local(''), url('../fonts/gilroy-bold.woff2') format('woff2'), url('../fonts/gilroy-bold.woff') format('woff')
  font-display swap